<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="路段名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入路段名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="所属部门" prop="depId">
                <a-input v-model="queryParam.depId" placeholder="请选择所属部门" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced"> </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:jt808:area:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:jt:attendanceStatus:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <!-- <a-button type="dashed" shape="circle" :loading="loading" :style="{ float: 'right' }" icon="reload" @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="status" slot-scope="text, record">
          <a-switch :checked="record.status === 0" @change="onStatusChange(record)" />
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)"> <a-icon type="edit" />修改 </a>
          <a-divider type="vertical" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined, 'read')"
            v-hasPermi="['tenant:jt808:deviceSim:query']"
          >
            <a-icon type="info-circle" />查看
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:jt:attendanceStatus:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:jt:attendanceStatus:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listAreaManager, enableAreaManager, delAreaManager } from '@/api/jt808/areaManager'
import CreateForm from './modules/CreateForm'
import ImportExcel from '../components/ImportExcel'

export default {
  name: 'AreaManager',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        deptId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '路段名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '几何类型',
          dataIndex: 'geomType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.geomType === 1) {
              return '圆形'
            } else if (record.geomType === 2) {
              return '矩形'
            } else if (record.geomType === 3) {
              return '多边形'
            } else if (record.geomType === 4) {
              return '路线'
            }
          }
        },

        // {
        //   title: '所属部门',
        //   dataIndex: 'depId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '限速',
          dataIndex: 'limitSpeed',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '限停',
          dataIndex: 'limitTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '限制出入',
          dataIndex: 'limitInOut',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.limitInOut === 0) return '无'
            else if (record.limitInOut === 1) {
              return '进区域'
            } else {
              return '出区域'
            }
          }
        },
        {
          title: '标记类型',
          dataIndex: 'markType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.markType === 1) return '作业区'
            else if (record.markType === 2) {
              return '停车场'
            } else if (record.markType === 3) {
              return '禁行区'
            } else {
              return '考勤区'
            }
          }
        },
        {
          title: '启用状态',
          dataIndex: 'status',
          width: '18%',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询sim卡管理列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      listAreaManager(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        deptId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delAreaManager(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    onStatusChange(record) {
      console.log('onStatusChange', record)
      enableAreaManager({
        id: record.id + '',
        enable: record.status === 0 ? 1 : 0
      }).then(res=>{
        this.$message.success('状态修改成功')
        record.status = record.status === 0 ? 1 : 0
      })
    }
  }
}
</script>
